import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const SendItemizedReceiptFormSkeleton = () => {
  return (
    <Box>
      <Skeleton
        variant="rect"
        height={55}
        width="100%"
        style={{ borderRadius: 4 }}
      />
      <Box mt={2}>
        <Skeleton variant="rect" height={41} width="100%" />
      </Box>
    </Box>
  );
};
