import { memo, useMemo } from "react";
import { Typography } from "@material-ui/core";
import { colors } from "@chhjpackages/components";
import clsx from "clsx";

import { Accordion } from "shared/ui";
import { CategoryIdEnum } from "shared/types";
import { replaceToBrTag } from "shared/utils";

import { useStyles } from "./styles";
import { NotesProps } from "./types";

export const Notes = memo(({ appointment }: NotesProps) => {
  const styles = useStyles();

  const category = useMemo(
    () => appointment.category.id,
    [appointment.category.id],
  );

  const moveOrMoveLabor = useMemo(
    () =>
      category === CategoryIdEnum.MoveLabor ||
      category === CategoryIdEnum.Moving,
    [category],
  );

  const type = useMemo(() => appointment.type, [appointment.type]);

  const notes = useMemo(() => appointment.notes, [appointment.notes]);

  const quoteNotes = useMemo(
    () =>
      appointment.quotes.length > 0
        ? {
            moveFactors: appointment.quotes[0].notes.moveFactors,
            specialInstructions:
              appointment.quotes[0].notes.specialInstructions,
          }
        : undefined,
    [appointment.quotes],
  );

  return (
    <>
      {moveOrMoveLabor && (
        <Accordion
          expandIconColor={colors.primary.main}
          header={
            <Typography
              variant="h5"
              className={clsx(styles.accordionHeader, styles.note)}
            >
              Move factors
            </Typography>
          }
        >
          <Typography
            variant="body1"
            className={styles.note}
            dangerouslySetInnerHTML={{
              __html: replaceToBrTag(notes?.movingFactors || ""),
            }}
          />
        </Accordion>
      )}

      {!moveOrMoveLabor && (
        <>
          <Accordion
            expandIconColor={colors.primary.main}
            header={
              <Typography
                variant="h5"
                className={clsx(styles.accordionHeader, styles.note)}
              >
                {`Type of ${
                  category === CategoryIdEnum.Labor ? "work" : "junk"
                }`}
              </Typography>
            }
          >
            <Typography
              variant="body1"
              className={styles.note}
              dangerouslySetInnerHTML={{
                __html: replaceToBrTag(notes?.typeOfJunk || ""),
              }}
            />
          </Accordion>

          <Accordion
            expandIconColor={colors.primary.main}
            header={
              <Typography
                variant="h5"
                className={clsx(styles.accordionHeader, styles.note)}
              >
                {`Location of ${
                  category === CategoryIdEnum.Labor ? "work" : "junk"
                }`}
              </Typography>
            }
          >
            <Typography
              variant="body1"
              className={styles.note}
              dangerouslySetInnerHTML={{
                __html: replaceToBrTag(notes?.locationOfJunk || ""),
              }}
            />
          </Accordion>
        </>
      )}

      <Accordion
        expandIconColor={colors.primary.main}
        header={
          <Typography
            variant="h5"
            className={clsx(styles.accordionHeader, styles.note)}
          >
            Special instructions
          </Typography>
        }
      >
        <Typography
          variant="body1"
          className={styles.note}
          dangerouslySetInnerHTML={{
            __html: replaceToBrTag(notes?.specialInstructions || ""),
          }}
        />
      </Accordion>

      {quoteNotes && type !== "EST" && (
        <Accordion
          expandIconColor={colors.primary.main}
          header={
            <Typography
              variant="h5"
              className={clsx(styles.accordionHeader, styles.note)}
            >
              Notes From Quote
            </Typography>
          }
        >
          <div className={styles.accordionContent}>
            {quoteNotes.moveFactors && (
              <div>
                <Typography variant="body1" className={styles.note}>
                  Move Factors:
                </Typography>

                <Typography
                  variant="body1"
                  className={styles.note}
                  dangerouslySetInnerHTML={{
                    __html: replaceToBrTag(quoteNotes.moveFactors),
                  }}
                />
              </div>
            )}

            {quoteNotes.specialInstructions && (
              <div>
                <Typography variant="body1" className={styles.note}>
                  Special Instructions:
                </Typography>

                <Typography
                  variant="body1"
                  className={styles.note}
                  dangerouslySetInnerHTML={{
                    __html: replaceToBrTag(quoteNotes.specialInstructions),
                  }}
                />
              </div>
            )}
          </div>
        </Accordion>
      )}
    </>
  );
});
