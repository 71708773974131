import { makeStyles } from "@material-ui/core";

export const useResultStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  contentRoot: {
    flex: 1,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  resultTextContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  successSection: {
    marginTop: 16,
  },
  errorSection: {
    marginTop: 32,
  },
  errorCode: {
    marginTop: 24,
    wordBreak: "break-word",
  },
}));
