import { Appointments } from "features/appointment";
import {
  squareApplicationAndroidTenderTypes,
  squareApplicationIOsTenderTypes,
  squareApplicationId,
} from "shared/config";
import { OSEnum } from "shared/utils";

const sdkVersion = "v2.0";

const clientTransactionIdFieldAndroid =
  "com.squareup.pos.CLIENT_TRANSACTION_ID";
const serverTransactionIdFiledAndroid =
  "com.squareup.pos.SERVER_TRANSACTION_ID";
const metadataFieldAndroid = "com.squareup.pos.REQUEST_METADATA";
const errorFieldAndroid = "com.squareup.pos.ERROR_CODE";

const clientTransactionIdFieldIOs = "client_transaction_id";
const serverTransactionIdFieldIOs = "transaction_id";
const metadataFieldIOs = "state";
const errorFieldIOs = "error_code";

const generateSquareMetaData = (
  appointment: Appointments,
  paymentBack?: string,
) => {
  return `${appointment.id}_${Boolean(appointment.subpartner.name)}${
    paymentBack ? `_${paymentBack}` : ""
  }`;
};

const getSquareMetaData = (meta: string) => {
  const data = meta.split("_");

  return {
    appointmentId: Number(data[0]),
    isNasa: Boolean(data[1]),
    paymentBack: data.length > 2 ? data[2] : undefined,
  };
};

export const getSquareLinkToStore = (os: string) => {
  if (os === OSEnum.android) {
    return "market://details?id=com.squareup";
  } else if (os === OSEnum.ios) {
    return "itms-apps://itunes.apple.com/app/square-point-of-sale/id335393788";
  }

  return null;
};

export const generateSquareDeepLink = (
  appointment: Appointments,
  currencyCode: "USD",
  os: string,
  locationOrigin: string,
  paymentBack?: string,
): string | null => {
  if (os === OSEnum.android) {
    return (
      "intent:#Intent;" +
      "action=com.squareup.pos.action.CHARGE;" +
      "package=com.squareup;" +
      "S.com.squareup.pos.WEB_CALLBACK_URI=" +
      `${locationOrigin}/payment/result` +
      ";" +
      "S.com.squareup.pos.CLIENT_ID=" +
      squareApplicationId +
      ";" +
      "S.com.squareup.pos.API_VERSION=" +
      sdkVersion +
      ";" +
      "i.com.squareup.pos.TOTAL_AMOUNT=" +
      String(Math.round(appointment.balanceDue * 100)) +
      ";" +
      "S.com.squareup.pos.CURRENCY_CODE=" +
      currencyCode +
      ";" +
      "S.com.squareup.pos.TENDER_TYPES=" +
      squareApplicationAndroidTenderTypes +
      ";" +
      "S.com.squareup.pos.REQUEST_METADATA=" +
      generateSquareMetaData(appointment, paymentBack) +
      ";" +
      "S.com.squareup.pos.NOTE=" +
      String(appointment.id) +
      ";" +
      "end"
    );
  } else if (os === OSEnum.ios) {
    const data = {
      amount_money: {
        amount: Math.round(appointment.balanceDue * 100),
        currency_code: currencyCode,
      },
      callback_url: `${locationOrigin}/payment/result`,
      client_id: squareApplicationId,
      version: "1.3",
      state: generateSquareMetaData(appointment, paymentBack),
      options: {
        supported_tender_types: squareApplicationIOsTenderTypes,
      },
      notes: String(appointment.id),
    };

    return `square-commerce-v1://payment/create?data=${encodeURIComponent(
      JSON.stringify(data),
    )}`;
  } else {
    return null;
  }
};

const getTransactionInfo = (stringUrl: string, os: string) => {
  const url = new URL(stringUrl);

  if (os === OSEnum.android) {
    var vars: Record<string, string> = {};

    stringUrl.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value;
      return "";
    });

    return vars;
  }

  if (os === OSEnum.ios) {
    const params = url.searchParams.get("data");
    if (params) {
      const data = decodeURI(params);

      var transactionInfo = JSON.parse(data);
      return transactionInfo;
    }
  }
};

export const getTransactionResult = (
  responseUrl: string,
  os: string,
): {
  success: boolean;
  errorCode: string;
  clientTransactionId: string | null;
  serverTransactionId: string | null;
  meta: {
    appointmentId: number;
    isNasa: boolean;
    paymentBack?: string;
  } | null;
} => {
  const transactionInfo = getTransactionInfo(responseUrl, os);

  let success = false;
  let errorCode = "";
  let clientTransactionId: string | null = null;
  let serverTransactionId: string | null = null;
  let meta: {
    appointmentId: number;
    isNasa: boolean;
    paymentBack?: string;
  } | null = null;

  if (os === OSEnum.android) {
    if (!(errorFieldAndroid in transactionInfo)) {
      success = true;
    } else {
      errorCode = transactionInfo[errorFieldAndroid];
    }

    if (clientTransactionIdFieldAndroid in transactionInfo) {
      clientTransactionId = transactionInfo[clientTransactionIdFieldAndroid];
    }

    if (serverTransactionIdFiledAndroid in transactionInfo) {
      serverTransactionId = transactionInfo[serverTransactionIdFiledAndroid];
    }

    if (metadataFieldAndroid in transactionInfo) {
      meta = getSquareMetaData(transactionInfo[metadataFieldAndroid]);
    }
  } else if (os === OSEnum.ios) {
    if (!(errorFieldIOs in transactionInfo)) {
      success = true;
    } else {
      errorCode = transactionInfo[errorFieldIOs];
    }

    if (clientTransactionIdFieldIOs in transactionInfo) {
      clientTransactionId = transactionInfo[clientTransactionIdFieldIOs];
    }

    if (serverTransactionIdFieldIOs in transactionInfo) {
      serverTransactionId = transactionInfo[serverTransactionIdFieldIOs];
    }

    if (metadataFieldIOs in transactionInfo) {
      meta = getSquareMetaData(transactionInfo[metadataFieldIOs]);
    }
  }

  return {
    success,
    errorCode,
    clientTransactionId,
    serverTransactionId,
    meta,
  };
};
