import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/styles";

export const useEtaBannerStyles = makeStyles({
  row: {
    width: "100%",
    padding: 16,
    minHeight: 44,
  },

  icon: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.grey180,
  },

  content: {
    flex: 1,
    position: "relative",
  },

  label: {
    position: "absolute",
    left: 0,
    bottom: "100%",
    color: colors.grey140,
  },

  actions: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
});
