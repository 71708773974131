import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  additionalContentContainer: {
    padding: "8px 24px",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  productTotalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  editingItemsList: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 16,
    backgroundColor: colors.grey10,
  },
  totalPriceContainer: {
    padding: "8px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  totalPriceTitle: {
    color: colors.grey140,
    fontWeight: "bold",
  },
  totalPrice: {
    cursor: "pointer",
    fontSize: 36,
    fontWeight: "bold",
  },
}));
