import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  controlPanelRoot: {
    flexWrap: "nowrap !important" as "nowrap",
  },
  controlPanelActionsContainer: {
    overflow: "hidden",
  },
  controlPanelContentContainer: {
    overflow: "hidden",
  },
  title: {
    whiteSpace: "nowrap",
  },
}));
