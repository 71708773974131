import { fetcher } from "shared/libs";
import { CreatePayment, SendPaymentLink } from "../types";

export const createPayment = ({
  locationId,
  appointmentId,
  payload,
}: CreatePayment.Params) =>
  fetcher.post<CreatePayment.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/payments`,
    payload,
  );

export const sendPaymentLink = ({
  locationId,
  appointmentId,
  payload,
}: SendPaymentLink.Params) =>
  fetcher.post<SendPaymentLink.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/payments/link`,
    payload,
  );
