import { useDialogDispatch } from "@chhjpackages/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react/compat";

import { Appointments } from "../types";
import { DialogContentWrapper } from "shared/ui";
import { Notes } from "features/appointment/ui";
import { $appointmentStore } from "../store";

export const useNotesDialog = ({
  appointment,
}: {
  appointment?: Appointments | null;
}) => {
  const setDialog = useDialogDispatch();

  const { appointment: appointmentStore } = useStore($appointmentStore);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean | null>(null);

  const appointmentForUse = useMemo(
    () => appointment ?? appointmentStore,
    [appointment, appointmentStore],
  );

  const handleNotesDialogOpen = useCallback(() => setIsDialogOpen(true), []);

  const handleNotesDialogClose = useCallback(() => setIsDialogOpen(false), []);

  useEffect(() => {
    if (appointmentForUse && isDialogOpen) {
      setDialog({
        open: true,
        variant: "basic",
        title: "Notes",
        disableBackdropClick: true,
        dialogContent: (
          <DialogContentWrapper maxWidth={488}>
            <Notes appointment={appointmentForUse} />
          </DialogContentWrapper>
        ),
        onClose: handleNotesDialogClose,
      });
    } else if (isDialogOpen === false) {
      setIsDialogOpen(null);
      setDialog({ open: false });
    }
  }, [isDialogOpen, appointmentForUse, setDialog, handleNotesDialogClose]);

  return { handleNotesDialogOpen, handleNotesDialogClose };
};
