import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 40,
  },
  workLinksList: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  actionButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  actionButton: {
    flex: 1,
  },
}));
