import { Navigate } from "react-router-dom";
import { ReactNode } from "react";

import {
  Contacts,
  Dashboard,
  JobDetails,
  Login,
  MapTypesDialog,
  NeedHelp,
  NotesDialog,
  NotFoundPage,
  NasaRequirements,
  ResetPasswordLink,
  ResetPasswordLinkSuccess,
  RestorePassword,
  RestorePasswordSuccess,
  Settings,
  EstimateDetail,
  UnprocessedChanges,
  CreateTeam,
  TermsAndConditions,
} from "pages";
import { routePaths } from "shared/utils";
import {
  Training,
  PerfectJobSteps,
  MoveJobSteps,
  BinderPresentationList,
  BinderPresentationDetails,
} from "pages/training";
import { LeaderBoard } from "pages/leaderboard";
import { UserProfile } from "pages/user-profile";
import { Pricings, PricingsAdd } from "pages/add-product";
import { Cart, CartEdit } from "pages/cart";
import {
  Coupons,
  CouponsAndDiscounts,
  Discounts,
} from "pages/coupons-and-discounts";
import { PaymentCollect, Payments, SendPaymentLink } from "pages/payments";
import { PaymentResult } from "pages/payments/result";
import {
  EditTravelDevelopments,
  EditWorkDevelopments,
} from "pages/developments";
import {
  BillOfLadingEstimate,
  BillOfLadingSignature,
} from "pages/bill-of-lading";
import {
  Attachments,
  CompleteAppointment,
  SendInvoice,
} from "pages/appointment";
import {
  JobInspectionSignature,
  JobInspectionTerms,
} from "pages/job-inspection";
import { Notifications } from "pages/notifications";
import { Valuation, ValuationSignature } from "pages/valuation";
import {
  PreExistingDamage,
  PreExistingDamageSignature,
} from "pages/pre-existing-damage";
import {
  LiabilityWaiver,
  LiabilityWaiverSignature,
} from "pages/liability-waiver";

export enum BlockRouteEnum {
  EST,
  JOB_CLOSED,
  NOT_MOVING,
  LW_COMPLETED,
  VAL_COMPLETED,
  BOL_COMPLETED,
  PED_COMPLETED,
  SQUARE_PAYMENT,
}

export interface PageRoute {
  component: ReactNode;
  path: string;
  nestedRoutes?: PageRoute[];
  isProtectedRoute?: boolean;
  blockRoute?: BlockRouteEnum[];
}

export const routes: PageRoute[] = [
  {
    path: routePaths.home(),
    component: <Navigate to={routePaths.dashboard()} replace />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.dashboard(),
    component: <Dashboard />,
    isProtectedRoute: true,
    nestedRoutes: [
      {
        path: routePaths.appointmentContacts(),
        component: <Contacts />,
      },
      {
        path: routePaths.appointmentNotes(),
        component: <NotesDialog />,
      },
      {
        path: routePaths.appointmentMapTypes(),
        component: <MapTypesDialog />,
      },
    ],
  },
  {
    path: routePaths.jobDetails(),
    component: <JobDetails />,
    isProtectedRoute: true,
    nestedRoutes: [
      {
        path: routePaths.jobDetailsNotesModal(),
        component: <NotesDialog />,
      },
      {
        path: routePaths.jobDetailsContacts(),
        component: <Contacts />,
      },
      {
        path: routePaths.jobDetailsSendInvoice(),
        component: <SendInvoice />,
      },
      {
        path: routePaths.jobDetailsCompleteAppointment(),
        blockRoute: [BlockRouteEnum.JOB_CLOSED],
        component: <CompleteAppointment />,
      },
      {
        path: routePaths.jobDetailsAttachments(),
        component: <Attachments />,
      },
    ],
  },
  {
    path: routePaths.leaderboards(),
    component: <LeaderBoard />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.notifications(),
    component: <Notifications />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.training(),
    component: <Training />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingPerfectJob(),
    component: <PerfectJobSteps />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingMoveJob(),
    component: <MoveJobSteps />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingBinderPresentation(),
    component: <BinderPresentationList />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingBinderPresentationDetails(),
    component: <BinderPresentationDetails />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsConvertToJob(),
    component: <EstimateDetail />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsAddProduct(),
    component: <Pricings />,
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.JOB_CLOSED],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsAddProductNew(),
        component: <PricingsAdd />,
      },
    ],
  },
  {
    path: routePaths.jobDetailsCart(),
    component: <Cart />,
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.JOB_CLOSED],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsCartEdit(),
        component: <CartEdit />,
      },
    ],
  },
  {
    path: routePaths.jobDetailsCouponsAndDiscounts(),
    component: <CouponsAndDiscounts />,
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.JOB_CLOSED],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsCoupons(),
        component: <Coupons />,
      },
      {
        path: routePaths.jobDetailsDiscounts(),
        component: <Discounts />,
      },
    ],
  },
  {
    path: routePaths.jobDetailsPayments(),
    component: <Payments />,
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsSendPaymentLink(),
        component: <SendPaymentLink />,
      },
    ],
  },
  {
    path: routePaths.jobDetailsPaymentsCollect(),
    component: <PaymentCollect />,
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.SQUARE_PAYMENT],
  },
  {
    path: routePaths.jobDetailsPaymentsResult(),
    component: <PaymentResult />,
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST],
  },
  {
    path: routePaths.jobDetailsEditTravelHours(),
    component: <EditTravelDevelopments />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsEditWorkHours(),
    component: <EditWorkDevelopments />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsLiabilityWaiver(),
    component: <LiabilityWaiver />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
    ],
  },
  {
    path: routePaths.jobDetailsLiabilityWaiverSignature(),
    component: <LiabilityWaiverSignature />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
    ],
  },
  {
    path: routePaths.jobDetailsValuation(),
    component: <Valuation />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
      BlockRouteEnum.VAL_COMPLETED,
    ],
  },
  {
    path: routePaths.jobDetailsValuationSignature(),
    component: <ValuationSignature />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
    ],
  },
  {
    path: routePaths.jobDetailsBillOfLading(),
    component: <BillOfLadingEstimate />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
      BlockRouteEnum.BOL_COMPLETED,
    ],
  },
  {
    path: routePaths.jobDetailsbillOfLadingSignature(),
    component: <BillOfLadingSignature />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
    ],
  },
  {
    path: routePaths.jobDetailsPreExistingDamage(),
    component: <PreExistingDamage />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
      BlockRouteEnum.PED_COMPLETED,
    ],
  },
  {
    path: routePaths.jobDetailsPreExistingDamageSignature(),
    component: <PreExistingDamageSignature />,
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
    ],
  },
  {
    path: routePaths.jobInspectionTerms(),
    component: <JobInspectionTerms />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobInspectionSignature(),
    component: <JobInspectionSignature />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.squarePaymentResult(),
    component: <PaymentResult />,
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST],
  },
  {
    path: routePaths.nasaRequirements(),
    component: <NasaRequirements />,
    isProtectedRoute: true,
    nestedRoutes: [
      {
        path: routePaths.nasaRequirementsAttachments(),
        component: <Attachments />,
      },
    ],
  },
  {
    path: routePaths.unprocessedChanges(),
    component: <UnprocessedChanges />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.leaderboards(),
    component: <LeaderBoard />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.settings(),
    component: <Settings />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.userProfile(),
    component: <UserProfile />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.createTeam(),
    component: <CreateTeam />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.editTeam(),
    component: <CreateTeam />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.login(),
    component: <Login />,
    isProtectedRoute: false,
  },
  {
    path: routePaths.termsAndConditions(),
    component: <TermsAndConditions />,
    isProtectedRoute: false,
  },
  {
    path: routePaths.needHelp(),
    component: <NeedHelp />,
    isProtectedRoute: false,
  },
  {
    path: routePaths.resetPasswordLink(),
    component: <ResetPasswordLink />,
    isProtectedRoute: false,
  },
  {
    path: routePaths.resetPasswordLinkSuccess(),
    component: <ResetPasswordLinkSuccess />,
    isProtectedRoute: false,
  },
  {
    path: routePaths.restorePassword(),
    component: <RestorePassword />,
    isProtectedRoute: false,
  },
  {
    path: routePaths.restorePasswordSuccess(),
    component: <RestorePasswordSuccess />,
    isProtectedRoute: false,
  },
  {
    component: <NotFoundPage />,
    path: routePaths.notFound(),
    isProtectedRoute: false,
  },
];
