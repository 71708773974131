import { colors } from "@chhjpackages/components";
import { FormHelperText, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const HelperTxt = withStyles(() => ({
  root: {
    fontSize: 12,
    lineHeight: 2,
    marginLeft: 2,
    marginRight: 2,
    color: colors.grey140,
  },
}))(FormHelperText);

export const useStyles = makeStyles({
  formControlLabel: {
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
    },
  },
});
