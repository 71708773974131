import { createEffect, createStore } from "effector/compat";

import { getSquareToken } from "../api";

export const $square = createStore<{ token: string | null; loading: boolean }>({
  token: null,
  loading: true,
});

export const getSquareTokenFx = createEffect(getSquareToken);

$square
  .on(getSquareTokenFx.doneData, (store, response) => ({
    ...store,
    token: response.data.mobileToken.authorizationCode,
  }))
  .on(getSquareTokenFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
