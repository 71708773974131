import { useAlert, useConfirmationDialog } from "@chhjpackages/components";
import { useCallback, useMemo } from "react";
import { useStore } from "effector-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { $teamsStore } from "features/teams";
import { $appointmentsStore, getAppointmentsFx } from "features/appointment";
import { AccountInfo } from "../ui";
import { CompletedActionsJobEnum } from "shared/types";
import { addCompletedActionFx } from "features/completed-actions";
import { apiDateFormat, apiDateTimeFormat, routePaths } from "shared/utils";
import { useLocationTimezone } from "shared/hooks";

export const useMarkEtaComplete = () => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { utcToZone } = useLocationTimezone("location");

  const { appointments } = useStore($appointmentsStore);
  const { userTeams } = useStore($teamsStore);

  const orderedAppointments = useMemo(() => {
    const assignedAppointments = appointments.filter((appointment) =>
      userTeams.some((team) => Number(team.id) === Number(appointment.team.id)),
    );

    const currentDate = moment(
      utcToZone(moment().utc().format(apiDateTimeFormat)),
    );
    const futureDate = currentDate.add(30, "minutes");

    return assignedAppointments
      .filter((appointment) => {
        return (
          (moment(appointment.startDate).isBefore(currentDate) ||
            futureDate.isSameOrAfter(appointment.startDate)) &&
          !appointment.completedactions.some(
            (action) =>
              action.type.id === CompletedActionsJobEnum.StartTravelETA,
          )
        );
      })
      .sort((a, b) => {
        if (moment(a.startDate).isBefore(b.startDate)) {
          return -1;
        }

        if (moment(b.startDate).isBefore(a.startDate)) {
          return 1;
        }

        if (moment(a.endDate).isBefore(b.startDate)) {
          return -1;
        }

        if (moment(b.endDate).isBefore(a.endDate)) {
          return 1;
        }

        if (moment(a.created.date).isBefore(b.created.date)) {
          return -1;
        }

        if (moment(b.created.date).isBefore(a.created.date)) {
          return 1;
        }

        return 0;
      });
  }, [appointments, userTeams, utcToZone]);

  const nextAppointment = useMemo(() => {
    if (orderedAppointments.length) {
      return orderedAppointments[0];
    }
  }, [orderedAppointments]);

  const onSendEta = useCallback(async () => {
    if (nextAppointment) {
      try {
        await addCompletedActionFx({
          appointmentId: nextAppointment.id,
          locationId: nextAppointment.location.id,
          payload: {
            type: {
              id: CompletedActionsJobEnum.ETACallCompleted,
            },
          },
        });

        await getAppointmentsFx({
          locationId: nextAppointment.location.id,
          date: utcToZone(
            moment.utc().format(apiDateTimeFormat),
            apiDateFormat,
          ),
        });

        showAlert("Success! ETA call completed.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to complete ETA call.", {
          variant: "error",
        });
      }
    }
  }, [nextAppointment, utcToZone]);

  const { closeConfirmation, openConfirmation, setLoader } =
    useConfirmationDialog({
      title: "Send ETA to client",
      message: nextAppointment ? (
        <AccountInfo appointment={nextAppointment} />
      ) : (
        <></>
      ),
      confirmButtonText: "Mark ETA as complete",
      cancelButtonText: "Exit",
    });

  const openMarkEtaComplete = useCallback(() => {
    if (
      nextAppointment?.completedactions.some(
        (action) => action.type.id === CompletedActionsJobEnum.ETACallCompleted,
      )
    ) {
      navigate(routePaths.jobDetails(nextAppointment.id));
    } else {
      openConfirmation(async () => {
        setLoader(true);
        await onSendEta();

        setLoader(false);
        closeConfirmation();
      });
    }
  }, [
    nextAppointment?.completedactions,
    nextAppointment?.id,
    navigate,
    onSendEta,
    openConfirmation,
  ]);

  return { openMarkEtaComplete, nextAppointment };
};
