import {
  Collapse,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStore } from "effector-react/compat";
import { useCallback, useMemo, memo, useEffect, useState } from "react";
import { colors, Paper, useAlert } from "@chhjpackages/components";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { InfoOutlined } from "@material-ui/icons";

import {
  $appointmentStore,
  updateAppointmentFx,
  getAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import {
  $productItems,
  $products,
  deleteProductItem,
  setProductsFx,
  triggerRecalculateProductItems,
} from "features/products";
import { BackTitle, ImportantBadge } from "shared/ui";
import { routePaths } from "shared/utils";
import { $developments, getDevelopmentsFx } from "features/developments";
import { $auth } from "features/auth";
import {
  $productUpdates,
  CartFooter,
  setProductUpdate,
  setProductUpdates,
} from "features/cart";
import { TotalTime, TotalTimeSkeleton } from "features/appointment";
import { useSideNavDispatch } from "features/sidenav";
import { useCompletedActions } from "features/completed-actions";
import { CompletedActionsJobEnum } from "shared/types";

import { ProductsList } from "./ui";

export const Cart = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

  const navigate = useNavigate();
  const location = useLocation();

  const { appointmentId } = useParams();
  const { setActionButton, clearActionButton } = useSideNavDispatch();
  const { deleteCompletedAction } = useCompletedActions();
  const { handleNotesDialogOpen } = useNotesDialog({});
  const { showAlert } = useAlert();

  const { locationId } = useStore($auth);
  const { products, loading: productsLoading } = useStore($products);
  const { productItems } = useStore($productItems);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const {
    developmentsAppointmentId,
    timeTravelled,
    billableTimeTravelled,
    timeWorked,
    billableTimeWorked,
    loading: developmentsLoading,
  } = useStore($developments);
  const { updates } = useStore($productUpdates);

  const [isSaving, setIsSaving] = useState(false);

  const locationState = useMemo<
    { isBillOfLading?: boolean; cartBack: string } | undefined
  >(() => location.state, [location.state]);

  const isBillOfLading = useMemo(
    () => Boolean(locationState?.isBillOfLading),
    [locationState?.isBillOfLading],
  );

  const noProducts = useMemo(
    () => !productsLoading && productItems.length === 0,
    [productsLoading, productItems.length],
  );

  const handleSaveAndContinue = useCallback(
    async (continueCallback?: (newProductsLength: number) => void) => {
      if (!appointment?.id || updates.length === 0) {
        if (!!continueCallback) {
          continueCallback(products.length);
        }
        return;
      }

      setIsSaving(true);

      const productsResult = products
        .map((product) => {
          const productInUpdates = updates.find(
            (update) => update.productLineId === product.productLineId,
          );

          if (productInUpdates) {
            return {
              actualPrice: productInUpdates.actualPrice,
              retailPrice: product.retailPrice,
              qty: productInUpdates.quantity,
              salesTaxId: productInUpdates.salesTaxId ?? 0,
              appointment: {
                id: appointment.id,
              },
              product: {
                id: product.productId,
              },
              notes: productInUpdates.notes,
            };
          }

          return {
            actualPrice: product.actualPrice,
            retailPrice: product.retailPrice,
            qty: product.quantity,
            salesTaxId: product.salesTaxId ?? 0,
            appointment: {
              id: appointment.id,
            },
            product: {
              id: product.productId,
            },
            notes: product.notes,
          };
        })
        .filter((product) => product.qty > 0);

      try {
        await setProductsFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            products: productsResult,
          },
        });
      } catch {
        showAlert("Error! Failed to update products. Try again later.", {
          variant: "error",
        });

        setIsSaving(false);

        return;
      }

      if (productsResult.length === 0) {
        await deleteCompletedAction(
          appointment.id,
          appointment.location.id,
          CompletedActionsJobEnum.AddProducts,
        );
      }

      let isUpdateAppointmentSuccess = true;
      try {
        await updateAppointmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
        });

        showAlert("Success! Products have been updated.", {
          variant: "success",
        });
      } catch {
        showAlert("Success! Products have been updated.", {
          variant: "success",
        });

        showAlert("Error! Failed to update appointment.", {
          variant: "error",
        });

        isUpdateAppointmentSuccess = false;
      }

      setProductUpdates([]);
      setIsSaving(false);

      if (isUpdateAppointmentSuccess) {
        if (!!continueCallback) {
          continueCallback(productsResult.length);
        }
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    },
    [
      appointment?.id,
      appointment?.location.id,
      updates,
      products,
      deleteCompletedAction,
      showAlert,
    ],
  );

  const handleBack = useCallback(() => {
    if (locationState?.isBillOfLading && products.length === 0) {
      navigate(routePaths.jobDetailsBillOfLading(Number(appointmentId)));
    } else if (locationState?.cartBack) {
      navigate(
        isBillOfLading && products.length > 0
          ? routePaths.jobDetails(Number(appointmentId))
          : locationState.cartBack,
        {
          state: { ...(locationState ?? {}) },
        },
      );
    } else {
      navigate(routePaths.jobDetailsAddProduct(Number(appointmentId)), {
        state: locationState,
      });
    }
  }, [appointmentId, isBillOfLading, products.length, locationState, navigate]);

  const handleAddProduct = useCallback(
    (shouldSave?: boolean) => {
      if (shouldSave && updates.length !== 0) {
        handleSaveAndContinue();
      } else {
        navigate(routePaths.jobDetailsAddProduct(Number(appointmentId)), {
          state: {
            ...(locationState ?? {}),
            addProductBack: location.pathname,
          },
        });
      }
    },
    [
      appointmentId,
      locationState,
      location.pathname,
      updates.length,
      handleSaveAndContinue,
      navigate,
    ],
  );

  const handleContinueToPayment = useCallback(() => {
    if (updates.length !== 0) {
      handleSaveAndContinue();
    } else {
      navigate(routePaths.jobDetailsPayments(Number(appointmentId)));
    }
  }, [appointmentId, updates.length, handleSaveAndContinue, navigate]);

  const handleBackToAppointment = useCallback(
    () => navigate(routePaths.jobDetails(Number(appointmentId))),
    [appointmentId, navigate],
  );

  const handleAddCoupounsAndDiscounts = useCallback(
    () =>
      navigate(routePaths.jobDetailsCoupons(Number(appointmentId)), {
        state: locationState,
      }),
    [appointmentId, locationState, navigate],
  );

  const handleEditDiscount = useCallback(
    () =>
      navigate(routePaths.jobDetailsDiscounts(Number(appointmentId)), {
        state: locationState,
      }),
    [appointmentId, locationState, navigate],
  );

  const handleContinueToSignature = useCallback(
    () =>
      handleSaveAndContinue((newProductsLength) => {
        if (!updates.length) {
          navigate(
            routePaths.jobDetailsbillOfLadingSignature(Number(appointmentId)),
            {
              state: {
                ...(locationState ?? {}),
                signatureBack:
                  newProductsLength > 0 ? location.pathname : undefined,
              },
            },
          );
        }
      }),
    [
      appointmentId,
      location.pathname,
      locationState,
      updates.length,
      handleSaveAndContinue,
      navigate,
    ],
  );

  const handleEditProduct = useCallback(
    (productLineId: number) =>
      navigate(
        routePaths.jobDetailsCartEdit(Number(appointmentId), productLineId),
        { replace: true, state: locationState },
      ),
    [appointmentId, locationState, navigate],
  );

  const handleDeleteProduct = useCallback(
    async (productLineId: number) => {
      const deletingProduct = productItems.find(
        (product) => product.productLineId === productLineId,
      );

      if (deletingProduct) {
        setProductUpdate({
          product: { ...deletingProduct, quantity: 0 },
          ignoreDelete: true,
        });

        deleteProductItem({ productLineId: deletingProduct.productLineId });
      }
    },
    [productItems],
  );

  useEffect(() => {
    if (Number(appointmentId) !== developmentsAppointmentId && locationId) {
      getDevelopmentsFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointmentId, developmentsAppointmentId, locationId]);

  useEffect(() => {
    if (Number(appointmentId) !== appointment?.id && locationId) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointment?.id, locationId, appointmentId]);

  useEffect(() => {
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });

    return () => {
      clearActionButton();
    };
  }, [
    appointmentLoading,
    clearActionButton,
    handleNotesDialogOpen,
    setActionButton,
  ]);

  useEffect(() => {
    return () => {
      setProductUpdates([]);
      triggerRecalculateProductItems();
    };
  }, []);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <BackTitle
        onBack={handleBack}
        title="Cart"
        actionButtons={[
          {
            name: "Add product",
            onClick: () => handleAddProduct(),
          },
          ...(!isMobile
            ? [
                {
                  name: "Notes",
                  icon: <InfoOutlined color="inherit" fontSize="small" />,
                  disabled: appointmentLoading,
                  onClick: handleNotesDialogOpen,
                },
              ]
            : []),
        ]}
      />
      <div
        style={{
          padding: 16,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isBillOfLading && productItems.length > 0 && (
          <ImportantBadge type="info" borderRadius={0} p={2} mb={2}>
            <Typography
              variant="body1"
              style={{ color: colors.complementary.blue.shade01 }}
            >
              To pre-fill items from the associated quote, please remove all
              items from the cart and access the bill of lading again.
            </Typography>
          </ImportantBadge>
        )}
        {!isBillOfLading && (
          <Paper elevation={16}>
            <div
              style={{
                backgroundColor: colors.white,
                padding: 16,
                paddingTop: 8,
                color: colors.grey190,
              }}
            >
              <Typography variant="h4" color="inherit">
                Total time
              </Typography>
              <div style={{ marginTop: 16 }}>
                {developmentsLoading ? (
                  <TotalTimeSkeleton />
                ) : (
                  <TotalTime
                    timeTravelled={timeTravelled}
                    billableTimeTravelled={billableTimeTravelled}
                    timeWorked={timeWorked}
                    billableTimeWorked={billableTimeWorked}
                  />
                )}
              </div>
            </div>
          </Paper>
        )}
        <div
          style={{
            flex: 1,
            marginTop: !isBillOfLading
              ? productsLoading || !noProducts
                ? 16
                : 0
              : 0,
            display: noProducts ? "flex" : "initial",
            alignItems: noProducts ? "center" : "initial",
            justifyContent: noProducts ? "center" : "initial",
          }}
        >
          <ProductsList
            products={productItems}
            loading={productsLoading}
            disable={isSaving}
            onEdit={handleEditProduct}
            onDelete={handleDeleteProduct}
          />
        </div>
      </div>
      <div
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: colors.white,
          zIndex: 100,
        }}
      >
        <Collapse in={!!appointment}>
          <CartFooter
            noProducts={noProducts}
            couponTotal={appointment?.couponTotal ?? 0}
            discountTotal={appointment?.discountTotal ?? 0}
            adminFee={appointment?.administrativeFee ?? 0}
            productTotal={appointment?.productTotal ?? 0}
            totalPrice={appointment?.subTotal ?? 0}
            isBillOfLading={isBillOfLading}
            actionsState={{
              addProduct: {
                title:
                  isSaving || (noProducts && updates.length > 0) ? "Save" : "",
                loading: isSaving,
              },
              continueToPayment: {
                title: !!updates.length ? "Save" : "",
                loading: isSaving,
              },
              continueToSignature: {
                title: !!updates.length ? "Save" : "",
                loading: isSaving,
              },
            }}
            onAddProduct={() => handleAddProduct(true)}
            onContinueToPayment={handleContinueToPayment}
            onBackToAppointment={handleBackToAppointment}
            onAddCouponsAndDiscounts={handleAddCoupounsAndDiscounts}
            onEditCoupon={handleAddCoupounsAndDiscounts}
            onEditDiscount={handleEditDiscount}
            onContinueToSignature={handleContinueToSignature}
          />
        </Collapse>
      </div>
      <Outlet />
    </div>
  );
});
