import { FC, memo, useMemo } from "react";
import { useController } from "react-hook-form";
import { FormControl } from "@material-ui/core";
import { TextArea } from "@chhjpackages/components";

import { HelperTxt } from "./styles";
import { FormTextAreaProps } from "./types";

export const FormTextArea: FC<FormTextAreaProps> = memo(
  ({
    isShowRequireTip = true,
    control,
    name,
    size = "medium",
    variant = "outlined",
    required,
    rowsMax,
    ...rest
  }) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: "",
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required && isShowRequireTip) {
        return "*required";
      }

      return "";
    }, [error, required, isShowRequireTip]);

    return (
      <FormControl error={!!error} fullWidth>
        <TextArea
          rowsMax={rowsMax}
          {...rest}
          {...field}
          variant={variant}
          size={size}
          required={required}
          error={!!error}
        />

        {!!helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
