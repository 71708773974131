import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  priceInput: {
    margin: "0!important",
    paddingLeft: "0!important",
  },
  inputAdornment: {
    marginTop: 2,
    marginLeft: "13px!important",
    marginRight: "4px!important",
    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: "bold",
      color: colors.secondary.main,
    },
  },
  checkboxWrapper: {
    marginLeft: 2,
    width: "fit-content",
  },
});
