import { colors } from "@chhjpackages/components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  content: {
    position: "relative",
  },

  label: {
    position: "absolute",
    left: 0,
    bottom: "calc(100% + 4px)",
    color: colors.grey140,
    whiteSpace: "nowrap",
  },

  actions: {
    display: "flex",
    alignItems: "center",
    gap: 24,
    marginLeft: 16,
  },

  actionButtonRoot: {
    padding: 0,
    background: "none!important",
    fontSize: 18,
    textAlign: "end",

    "&:hover": {
      color: colors.primary.dark,
    },
  },
});
