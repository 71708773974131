import { Terminal } from "features/payments/model";

export type PaymentFormProps = {
  terminals: Terminal[];
  initialValues: Partial<PaymentFormValues>;
  onSubmit: (values: PaymentFormValues) => Promise<void>;
};

export type PaymentFormValues = {
  method: {
    label: string;
    value: number;
  };
  amount: string;
  terminal?: {
    label: string;
    value: string;
  };
  isContactless?: boolean;
  paymentDate?: string;
  checkNumber?: string;
  depositDate?: string;
  depositNumber?: string;
  notes?: string;
  cardType?: {
    label: string;
    value: number;
  };
  cardNumber?: string;
  cardName?: string;
  expMonth?: {
    label: string;
    value: number;
  };
  expYear?: {
    label: string;
    value: number;
  };
  address1?: string;
  address2?: string;
  zip?: string;
  transactionId?: string;
  authCode?: string;
};

export enum PaymentMethodsEnum {
  Cash = 1,
  Check = 2,
  CreditCard = 3,
  eCheck = 4,
  Other = 6,
}
