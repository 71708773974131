import { ClickAwayListener, Collapse, Typography } from "@material-ui/core";
import { memo, useState } from "react";

import { BottomPageButton } from "shared/ui";
import { numberToCurrency, numberWithCommas } from "shared/utils";

import { CartFooterProps } from "./model";
import { EditingItem } from "./ui";
import { useStyles } from "./assets";

export const CartFooter = memo(
  ({
    noProducts,
    couponTotal,
    discountTotal,
    productTotal,
    adminFee,
    totalPrice,
    isBillOfLading,
    actionsState,
    onAddProduct,
    onContinueToPayment,
    onBackToAppointment,
    onAddCouponsAndDiscounts,
    onEditCoupon,
    onEditDiscount,
    onContinueToSignature,
  }: CartFooterProps) => {
    const styles = useStyles();

    const [isShowCouponsAndDiscounts, setIsShowCouponsAndDiscounts] =
      useState(false);

    return (
      <div>
        {noProducts ? (
          !isBillOfLading && (
            <BottomPageButton
              buttonType="filled"
              size="large"
              fullWidth
              isLoading={actionsState?.addProduct?.loading}
              disabled={actionsState?.addProduct?.disabled}
              onClick={onAddProduct}
            >
              {actionsState?.addProduct?.title || "Add a product"}
            </BottomPageButton>
          )
        ) : (
          <>
            <ClickAwayListener
              onClickAway={() =>
                isShowCouponsAndDiscounts && setIsShowCouponsAndDiscounts(false)
              }
            >
              <Collapse in={isShowCouponsAndDiscounts}>
                <div className={styles.additionalContentContainer}>
                  <div className={styles.productTotalContainer}>
                    <Typography variant="h4">Product total:</Typography>

                    <Typography variant="h4">
                      <b>${(productTotal || 0).toFixed(2)}</b>
                    </Typography>
                  </div>

                  <div className={styles.editingItemsList}>
                    <EditingItem
                      title="Discounts"
                      value={discountTotal || 0}
                      onEdit={onEditDiscount}
                    />

                    <EditingItem
                      title="Coupons"
                      value={couponTotal || 0}
                      onEdit={onEditCoupon}
                    />

                    {!!adminFee && (
                      <EditingItem
                        title="Administrative fee"
                        value={adminFee || 0}
                      />
                    )}
                  </div>
                </div>
              </Collapse>
            </ClickAwayListener>

            <div className={styles.totalPriceContainer}>
              <Typography variant="body1" className={styles.totalPriceTitle}>
                Total price:
              </Typography>

              <Typography
                variant="body1"
                color="secondary"
                component="div"
                className={styles.totalPrice}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowCouponsAndDiscounts((prev) => !prev);
                }}
              >
                ${(totalPrice || 0).toFixed(2)}
              </Typography>
            </div>

            <Collapse in={!isShowCouponsAndDiscounts}>
              <BottomPageButton
                buttonType="text"
                size="large"
                color="primary"
                fullWidth
                onClick={onAddCouponsAndDiscounts}
              >
                Add discounts & coupons
              </BottomPageButton>
            </Collapse>

            {!isBillOfLading && (
              <BottomPageButton
                buttonType="filled"
                size="large"
                fullWidth
                isLoading={actionsState?.continueToPayment?.loading}
                disabled={actionsState?.continueToPayment?.disabled}
                onClick={onContinueToPayment}
              >
                {actionsState?.continueToPayment?.title ||
                  "Continue to payment"}
              </BottomPageButton>
            )}
          </>
        )}

        {!noProducts && !isBillOfLading && (
          <BottomPageButton
            buttonType="text"
            size="large"
            fullWidth
            onClick={onBackToAppointment}
          >
            Back to appointment
          </BottomPageButton>
        )}

        {isBillOfLading && (
          <BottomPageButton
            buttonType="filled"
            size="large"
            fullWidth
            isLoading={actionsState?.continueToPayment?.loading}
            disabled={actionsState?.continueToPayment?.disabled}
            onClick={onContinueToSignature}
          >
            {actionsState?.continueToSignature?.title ||
              "Continue to crew signature"}
          </BottomPageButton>
        )}
      </div>
    );
  },
);
