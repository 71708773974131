import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  stepLinksContainer: {
    margin: "32px 0px",
  },
  quickAddContainer: {
    marginBottom: 40,
  },
  quickAddCardWrapper: {
    padding: "16px 10px",
    marginRight: -10,
  },
  quickAddArrowsContainer: {
    marginTop: 2,
  },
  pricingsListWrapper: {
    marginTop: 8,
  },
  buttonContainer: {
    marginTop: 16,
  },
}));
