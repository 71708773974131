import { createEffect } from "effector/compat";

import { fetcher } from "shared/libs";
import { GetTerminals, MakeHunkPayPayment } from "../types";

export const getTerminals = ({ locationId }: GetTerminals.Params) =>
  fetcher.get<GetTerminals.Response>(`/hunkpay/stores/${locationId}/terminals`);

export const makeHunkPayPayment = ({
  locationId,
  appointmentId,
  payload,
}: MakeHunkPayPayment.Params) =>
  fetcher.post<MakeHunkPayPayment.Response>(
    `/hunkpay/stores/${locationId}/appointments/${appointmentId}/payments`,
    payload,
  );

export const makeHunkPayPaymentFx = createEffect(makeHunkPayPayment);
