import { Attachment } from "features/attachments";
import {
  BasicUserAccount,
  CompletedAction,
  CouponAppointment,
  DataItem,
  Discount,
  Note,
  Place,
  Product,
  Subpartner,
  TaxCode,
  TaxRate,
  TimeZone,
} from "shared/types";

export enum AccountType {
  "Commercial" = 3,
  "Residential" = 5,
}

export const AccountTypes: Record<AccountType, string> = {
  3: "Commercial",
  5: "Residential",
};

export type UserData = {
  id: number;
  first_name: string;
  last_name: string;
  company_name: string;
  phone: string;
  work: string;
  cell: string;
  email: string;
  type: {
    id: number;
    name: string;
  };
};

export type AppointmentType = "ALL" | "JOB" | "EST" | "LJOB";

export const AppointmentTypes: Record<AppointmentType, string> = {
  ALL: "All types",
  JOB: "Job",
  EST: "Estimate",
  LJOB: "Linked",
};

export enum AppointmentTypesEnum {
  ALL = "ALL",
  EST = "EST",
  JOB = "JOB",
  LJOB = "LJOB",
}

export type AppointmentInvoice = {
  id: number;
};

export type QuoteModel = {
  id: number;
  notes: {
    moveFactors: string;
    specialInstructions: string;
  };
  numHunks: number;
  type: DataItem;
  metUrl: string;
  location: DataItem;
  created: {
    datetime: string;
  } & DataItem;
};

export type AppointmentLocation = {
  id: number;
  name: string;
  status: {
    id: number;
    name: string;
  };
  taxCodes: TaxCode[];
  timeZone: TimeZone;
};

export type AppointmentAction = {
  completed: Date;
  id: number;
  location: AppointmentLocation;
  type: { id: number; name: string };
};

export type Appointments = {
  account: BasicUserAccount;
  administrativeFeeEnabled: number;
  administrativeFee: number;
  attachments: Attachment[];
  amountPaid: number;
  appointmentTaxTotal: number;
  balanceDue: number;
  clientConfirmed: boolean;
  category: {
    id: number;
    name: string;
  };
  completedactions: CompletedAction[];
  convertedAppointmentId: number;
  couponTotal: number;
  coupon: CouponAppointment;
  created: {
    id: number;
    name: string;
    date: Date;
  };
  destination: Place;
  discountTotal: number;
  discount: Discount;
  endDate: string;
  estStatus: {
    id: number;
    name: string;
  };
  grandTotal: number;
  id: number;
  invoice: AppointmentInvoice;
  linked?: number[];
  location: AppointmentLocation;
  net: number;
  notes: Note;
  numHunks: number;
  origin: Place;
  originalDate?: string;
  purchaseOrder: string;
  startDate: string;
  taxRate: TaxRate;
  type: keyof typeof AppointmentTypes;
  productServicesTotal: number;
  productTotal: number;
  quotes: QuoteModel[];
  refundDays: number;
  status: {
    id: number;
    name: string;
  };
  stops: Place[];
  subTotal: number;
  team: AppointmentTeamResponseData;
  tipAmount: number;
  schedules: AppointmentScheduleResponseData[];
  source: DataItem;
  subpartner: Subpartner;
  parent: {
    id: number;
  };
  products: Product[];
  payments: AppointmentPaymentResponseData[];
  pricing: PricingResponse[];
  zone: { id: number; name: string };
  royalty: { id: number; isSubmitted: boolean };
  // actualStep: string;
};

export type PricingResponse = {
  id: number;
  name: string;
  subcategory: {
    id: number;
    name: string;
    category: {
      id: number;
      name: string;
    };
  };
  icon: string;
  price: number;
  active: boolean;
  tax: {
    id: number;
    name: string;
    isTaxable: boolean;
  };
};

export type AppointmentTeamResponseData = {
  id: number;
  name: string;
  captain: {
    id: number;
    name: string;
  };
};

export type AppointmentScheduleResponseData = {
  id: number;
  startDate: string;
  endDate: string;
  resource: {
    id: number;
    name: string;
  };
  development: {
    id: number;
    name: string;
  };
  team: {
    id: number;
    name: string;
  };
  colors: {
    background: string;
    foreground: string;
  };
};

export type AppointmentPaymentResponseData = {
  id: number;
  account_id: number;
  location_id: number;
  appointment_id: number;
  payment: {
    amount: number;
    auth_code: string;
    batch: string;
    check_number: string;
    credit_card: {
      address: string;
      name: string;
      number: string;
      type_id: string;
      zip: string;
      expiry_month: number;
      expiry_year: number;
      expiry: number;
    };
    created_date: string;
    date: string;
    memo: string;
    transaction_id: string;
    method: {
      id: number;
      name: string;
    };
    created_by: string;
    zone: {
      id: number;
      name: string;
    };
  };
};
