import { useCallback, MouseEvent as MouseEventReact, memo } from "react";
import { Box, IconButton } from "@material-ui/core";
import { colors, RiftFont } from "@chhjpackages/components";
import { ArrowUpward } from "@material-ui/icons";

import { BackToTopButtonProps } from "./types";

export const BackToTopButton = memo(({ style }: BackToTopButtonProps) => {
  const handleToTopClick = useCallback(
    (e: MouseEventReact<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      document.getElementById("root")?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    [],
  );

  return (
    <Box position="fixed" right={5} style={{ zIndex: 5, ...style }}>
      <IconButton color="inherit" onClick={handleToTopClick}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={36}
            width={40}
            height={40}
            bgcolor={colors.primary.main}
            mb={0.5}
            color={colors.basic.white}
          >
            <ArrowUpward color="inherit" />
          </Box>

          <Box
            color={colors.basic.black}
            fontSize={13}
            fontFamily={RiftFont}
            fontWeight={700}
            lineHeight="25px"
            letterSpacing={0.22}
          >
            Scroll Top
          </Box>
        </Box>
      </IconButton>
    </Box>
  );
});
