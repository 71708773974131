import { Button, useAlert } from "@chhjpackages/components";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "effector-react/compat";

import { useSideNavDispatch } from "features/sidenav";
import {
  PaymentProviderEnum,
  generateSquareDeepLink,
  usePaymentProvider,
  getSquareLinkToStore,
} from "features/payments";
import { BackTitle, OverlayLoader } from "shared/ui";
import {
  $appointmentStore,
  getAppointmentFx,
  updateAppointmentFx,
} from "features/appointment";
import { getOS, routePaths } from "shared/utils";
import { $auth, $location } from "features/auth";

import { CartTotal } from "./widgets";
import { CartTotalSkeleton } from "./widgets/cart-total/skeleton";
import { usePaymentsStyles } from "./assets";

export const Payments = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const styles = usePaymentsStyles();

  const location = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const { setPageName, setShowGoBackButton, setGoToBackUrl } =
    useSideNavDispatch();
  const paymentProvider = usePaymentProvider();

  const { location: locationStore } = useStore($location);
  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const { locationId } = useStore($auth);

  const [chargeAdminFee, setChargeAdminFee] = useState(false);
  const [isChangingAdminFee, setIsChangingAdminFee] = useState(false);

  const [squareAppOpened, setSquareAppOpened] = useState<boolean | null>(null);
  const [takePaymentNowIsLoading, setTakePaymentNowIsLoading] = useState(false);

  const system = useMemo(() => getOS(), []);

  const isSquare = useMemo(
    () => paymentProvider === PaymentProviderEnum.Square,
    [paymentProvider],
  );

  const isLoading = useMemo(
    () => appointmentLoading || paymentProvider === null,
    [appointmentLoading, paymentProvider],
  );

  const isShowChargeAdminFee = useMemo(
    () =>
      (appointment?.administrativeFee ?? 0) > 0 ||
      (locationStore?.adminFeeAmount ?? 0) > 0,
    [locationStore?.adminFeeAmount, appointment?.administrativeFee],
  );

  const goToBackUrl = useMemo(
    () =>
      location.state?.paymentBack ??
      routePaths.jobDetailsCart(Number(appointmentId)),
    [appointmentId, location.state?.paymentBack],
  );

  const handleTakePaymentNow = useCallback(async () => {
    if (!appointment) {
      return;
    }

    if (isSquare) {
      const deepLink = generateSquareDeepLink(
        appointment,
        "USD",
        system,
        window.location.origin,
        location.state?.paymentBack,
      );

      if (deepLink) {
        setTakePaymentNowIsLoading(true);

        window.location.href = deepLink;

        setTimeout(() => {
          setTakePaymentNowIsLoading(false);

          if (document.hidden) {
            setSquareAppOpened(true);
          } else {
            setSquareAppOpened(false);
          }
        }, 1000);
      } else {
        showAlert("Available only on mobile devices.", {
          variant: "warning",
        });
      }
    } else {
      navigate(routePaths.jobDetailsPaymentsCollect(appointment.id), {
        state: location.state,
      });
    }
  }, [system, appointment, location.state, isSquare, showAlert, navigate]);

  const handleSendPaymentLink = useCallback(() => {
    navigate(routePaths.jobDetailsSendPaymentLink(Number(appointmentId)), {
      state: location.state,
      replace: true,
    });
  }, [appointmentId, location.state, navigate]);

  const handleClickBack = useCallback(() => {
    navigate(goToBackUrl);
  }, [goToBackUrl, navigate]);

  const handleChargeAdminFee = useCallback(
    async (value: boolean) => {
      if (!appointment?.id) {
        showAlert("Error! Failed to update admin fee.", { variant: "error" });
        return;
      }

      setIsChangingAdminFee(true);
      setChargeAdminFee(value);

      try {
        await updateAppointmentFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            admin_fee: 0,
            administrative_fee_enabled: Number(value),
          },
        });
      } catch (err) {
        setChargeAdminFee(!value);
        showAlert("Error! Failed to update admin fee.", { variant: "error" });
      }

      setIsChangingAdminFee(false);
    },
    [appointment?.id, appointment?.location.id, showAlert],
  );

  useEffect(() => {
    setPageName("Payment method");
    setShowGoBackButton();
    setGoToBackUrl(goToBackUrl);

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton();
    };
  }, [goToBackUrl, setPageName, setShowGoBackButton, setGoToBackUrl]);

  useEffect(() => {
    if (squareAppOpened === null) {
      return;
    }

    if (!squareAppOpened) {
      const squareAppStoreLink = getSquareLinkToStore(system);

      if (squareAppStoreLink) {
        window.location.href = squareAppStoreLink;
      }
    }

    setSquareAppOpened(null);
  }, [squareAppOpened, system]);

  useEffect(() => {
    if (appointment?.administrativeFeeEnabled !== undefined) {
      setChargeAdminFee(!!appointment.administrativeFeeEnabled);
    }
  }, [appointment?.administrativeFeeEnabled]);

  useEffect(() => {
    if (
      Number(appointmentId) &&
      locationId &&
      appointment?.id !== Number(appointmentId)
    ) {
      getAppointmentFx({
        appointmentId: Number(appointmentId),
        locationId: locationId,
      });
    }
  }, [appointmentId, appointment?.id, locationId]);

  return (
    <>
      <OverlayLoader open={isChangingAdminFee} />

      {!isMobile && (
        <BackTitle onBack={handleClickBack} title="Payment method" />
      )}

      <div className={styles.root}>
        {!isLoading && !!appointment ? (
          <CartTotal
            cartTotal={appointment.subTotal}
            chargeAdminFee={chargeAdminFee}
            paid={appointment.amountPaid}
            adminFee={appointment.administrativeFee}
            taxes={appointment.appointmentTaxTotal}
            tips={appointment.tipAmount}
            balanceDue={appointment.balanceDue}
            permissions={{
              hideChargeAdminFee: !isShowChargeAdminFee,
              hideTips: appointment.tipAmount === 0,
            }}
            onChangeAdminFee={handleChargeAdminFee}
          />
        ) : (
          <CartTotalSkeleton />
        )}

        <div className={styles.actionButtonsContainer}>
          <Button
            buttonType="twoTone"
            size="large"
            fullWidth
            disabled={isLoading}
            isLoading={takePaymentNowIsLoading}
            onClick={handleTakePaymentNow}
          >
            Take payment now
          </Button>
          <Button
            buttonType="text"
            color="primary"
            size="large"
            fullWidth
            disabled={isLoading}
            onClick={handleSendPaymentLink}
          >
            Client not present for payment
          </Button>
        </div>
      </div>

      <Outlet />
    </>
  );
});
