import {
  CSSProperties,
  ReactNode,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";

import { Appointments } from "features/appointment";
import { useLocationTimezone } from "shared/hooks";
import { apiDateTimeFormat } from "shared/utils";

import { useEtaBannerStyles } from "./styles";

export const EtaBanner = memo(
  ({
    appointment,
    style,
    onClick,
  }: {
    appointment: Appointments;
    style?: CSSProperties;
    onClick?: () => void;
  }) => {
    const styles = useEtaBannerStyles();

    const { utcToZone } = useLocationTimezone("location");

    const [counter, setCount] = useState<number>();
    const interval = useRef<ReturnType<typeof setInterval>>();

    useEffect(() => {
      const differenceInMinutes = moment(appointment.startDate).diff(
        utcToZone(moment().utc().format(apiDateTimeFormat)),
        "minutes",
      );

      if (differenceInMinutes > 0 && differenceInMinutes <= 30) {
        setCount(differenceInMinutes * 60);
        interval.current = setInterval(() => {
          setCount((prev) => {
            if (prev === 1) {
              clearInterval(interval.current);
              return 0;
            }

            return prev ? prev - 1 : 0;
          });
        }, 1000);
      }

      return () => {
        if (interval.current) {
          clearInterval(interval.current);
        }
      };
    }, [appointment]);

    const buttonText = useMemo(() => {
      let text: ReactNode = <>Job {appointment.id} is now late</>;

      if (counter) {
        const duration = moment.duration(counter, "seconds");
        text = (
          <>
            Job due in{" "}
            <span className={styles.dueTime}>
              {moment.utc(duration.asMilliseconds()).format("mm:ss")}
            </span>
          </>
        );
      }

      const isEtaSent = appointment.completedactions.some(
        (action) => action.type.id === 23,
      );
      if (!isEtaSent) {
        text = <>{text}. Send client an ETA</>;
      }

      return text;
    }, [appointment.completedactions, counter]);

    return (
      <div className={styles.container} style={style}>
        <div className={styles.button} onClick={onClick}>
          <Typography variant="h5" color="inherit">
            {buttonText}
          </Typography>
        </div>
      </div>
    );
  },
);
