import {
  ConvertEstimateToJob,
  GetAppointment,
  RescheduleAppointment,
  UpdateAppointment,
  SendAppointmentInvoice,
  Appointments,
} from "../types";
import { fetcher } from "shared/libs";
import { AppointmentStatus } from "shared/types/appointment";

export const getAppointment = ({
  locationId,
  appointmentId,
}: GetAppointment.Params) =>
  fetcher.get<GetAppointment.Response>(
    `/locations/${locationId}/appointments/${appointmentId}?expand=schedules~linked~stops~quotes~operation_logs~products~payments~original_date~zone~subpartner~completedactions~quotes~attachments~pricing`,
  );

export const updateAppointment = ({
  locationId,
  appointmentId,
  payload,
  config,
}: UpdateAppointment.Params) =>
  fetcher.put<UpdateAppointment.Response>(
    `/locations/${locationId}/appointments/${appointmentId}?expand=schedules~linked~stops~quotes~operation_logs~products~payments~original_date~zone~subpartner~completedactions~quotes~attachments`,
    payload,
    config,
  );

export const markAppointmentLost = async ({
  locationId,
  appointmentId,
  type,
}: GetAppointment.Params & { type: Appointments["type"] }) => {
  const data = {
    ...(type === "EST"
      ? {
          estStatus: {
            id: AppointmentStatus.Estimate.Lost,
          },
        }
      : {
          status: {
            id: AppointmentStatus.Job.Lost,
          },
        }),
  };

  const response = await fetcher.put<GetAppointment.Response>(
    `/locations/${locationId}/appointments/${appointmentId}?expand=schedules~linked~stops~quotes~operation_logs~products~payments~original_date~zone~subpartner~completedactions~quotes~attachments`,
    data,
  );

  return response;
};

export const markAppointmentCompleted = async ({
  locationId,
  appointmentId,
  type,
}: GetAppointment.Params & { type: Appointments["type"] }) => {
  const data = {
    ...(type === "EST"
      ? {
          estStatus: {
            id: AppointmentStatus.Estimate.Completed,
          },
        }
      : {
          status: {
            id: AppointmentStatus.Job.Completed,
          },
        }),
  };

  const response = await fetcher.put<GetAppointment.Response>(
    `/locations/${locationId}/appointments/${appointmentId}?expand=schedules~linked~stops~quotes~operation_logs~products~payments~original_date~zone~subpartner~pricing~completedactions~quotes~attachments`,
    data,
  );

  return response;
};

export const convertEstimateToJob = ({
  locationId,
  appointmentId,
}: ConvertEstimateToJob.Params) =>
  fetcher.post<ConvertEstimateToJob.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/estimates/convert`,
  );

export const rescheduleAppointment = ({
  locationId,
  appointmentId,
  payload,
}: RescheduleAppointment.Params) =>
  fetcher.post<ConvertEstimateToJob.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/reschedule`,
    payload,
  );

export const sendAppointmentInvoice = ({
  locationId,
  appointmentId,
  payload,
}: SendAppointmentInvoice.Params) =>
  fetcher.post<SendAppointmentInvoice.Response>(
    `/locations/${locationId}/appointments/${appointmentId}/invoices`,
    payload,
  );
