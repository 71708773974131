import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "effector-react/compat";
import { InputAdornment, useMediaQuery, useTheme } from "@material-ui/core";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { colors, Input, Scrollbars, useAlert } from "@chhjpackages/components";
import moment from "moment";
import { InfoOutlined } from "@material-ui/icons";

import {
  PricingCategoriesEnum,
  PricingCategoriesNameEnum,
} from "features/pricings/model/types";
import { apiDateFormat, groupBy, routePaths, sortObject } from "shared/utils";
import {
  $pricingItems,
  $pricings,
  deletePricingItem,
  getPricingsFx,
  setFavoritePricingEv,
  triggerRecalculatePricingItems,
  updatePricingItem,
} from "features/pricings";
import {
  ActionsFooter,
  BackTitle,
  CategorySelect,
  FilterPicker,
} from "shared/ui";
import { SearchIcon } from "shared/assets";
import {
  $appointmentStore,
  getAppointmentFx,
  updateAppointmentFx,
  useNotesDialog,
} from "features/appointment";
import {
  $products,
  pricingsCategoryOptions,
  pricingsFilterOptions,
  setProductsFx,
} from "features/products";
import { CategoryIdEnum, CompletedActionsJobEnum } from "shared/types";
import { PricingItem, deletePricingUpdate } from "features/add-product";
import { $auth } from "features/auth";
import { useSideNavDispatch } from "features/sidenav";
import { $developments, getDevelopmentsFx } from "features/developments";
import {
  $pricingUpdates,
  setPricingUpdate,
  setPricingUpdates,
} from "features/add-product";
import { useCompletedActions } from "features/completed-actions";

import { PricingsList } from "./ui";
import { useStyles } from "./assets";

export const Pricings = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const styles = useStyles();

  const { appointmentId } = useParams<"appointmentId">();
  const navigate = useNavigate();
  const location = useLocation();

  const { showAlert } = useAlert();
  const { setActionButton, clearActionButton } = useSideNavDispatch();
  const { addCompletedAction, deleteCompletedAction } = useCompletedActions();
  const { handleNotesDialogOpen } = useNotesDialog({});

  const { appointment, loading: appointmentLoading } =
    useStore($appointmentStore);
  const { locationId } = useStore($auth);
  const { pricingsAppointmentId, loading: pricingsLoading } =
    useStore($pricings);
  const { pricingItems } = useStore($pricingItems);
  const { products } = useStore($products);
  const { developmentsAppointmentId } = useStore($developments);
  const { updates } = useStore($pricingUpdates);

  const lastCategory = useRef<number | null>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filters, setFilters] = useState<{
    search: string;
    category: number | null;
    type: number | null;
  }>({
    search: "",
    category: null,
    type: null,
  });
  const [pricingForUpdate, setPricingForUpdate] = useState<PricingItem | null>(
    null,
  );

  const sortedPricings = useMemo(
    () =>
      pricingItems
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort(
          (a, b) =>
            Number(b.isFavorite && !b.hideFavorite) -
            Number(a.isFavorite && !a.hideFavorite),
        ),
    [pricingItems],
  );

  const searchedPricings = useMemo(
    () =>
      sortedPricings.filter((pricing) =>
        pricing.name.toLowerCase().includes(filters.search.toLowerCase()),
      ),
    [sortedPricings, filters.search],
  );

  const filteredPricings = useMemo(() => {
    if (filters.category === null || sortedPricings.length === 0) {
      return [];
    }

    return searchedPricings
      .map((pricing) => ({
        ...(updates.find(
          (updatedPricing) => pricing.key === updatedPricing.key,
        ) ?? pricing),
        name: pricing.name,
        isFavorite: pricing.isFavorite,
        isHidden: pricing.isHidden,
        hideFavorite: pricing.hideFavorite,
      }))
      .filter(
        (pricing) =>
          (typeof filters.category === "number"
            ? filters.category === PricingCategoriesEnum.All ||
              pricing.category.id === filters.category
            : true) &&
          (typeof filters.type === "number"
            ? pricing.type.id === filters.type
            : true) &&
          !pricing.isHidden,
      );
  }, [filters.category, filters.type, searchedPricings]);

  const pricingTypeFilterOptions = useMemo(() => {
    if (!filters.category === null) {
      return [];
    }

    const filteredPricingsByCategory = searchedPricings.filter((pricing) =>
      typeof filters.category === "number"
        ? filters.category === PricingCategoriesEnum.All ||
          pricing.category.id === filters.category
        : true,
    );

    const filteredPricingsFilterOptions = pricingsFilterOptions.filter(
      (option) =>
        filteredPricingsByCategory.some(
          (pricing) => pricing.type.id === option.value,
        ),
    );

    if (!filteredPricingsFilterOptions.some((a) => a.value === filters.type)) {
      setFilters((prevValue) => ({ ...prevValue, type: null }));
    }

    return filteredPricingsFilterOptions;
  }, [searchedPricings, filters.category]);

  const noPricings = useMemo(
    () => !pricingsLoading && pricingItems.length === 0,
    [pricingsLoading, pricingItems.length],
  );

  const groupedPricings: Record<
    string,
    Record<string, PricingItem[]>
  > = useMemo(() => {
    const groupedByCategory = groupBy(
      filteredPricings,
      (pricing) => pricing.category.name,
    );

    const groupedByCategoryAndType = Object.entries(groupedByCategory).reduce(
      (acc, [name, items]) => ({
        ...acc,
        [name]: sortObject(groupBy(items, (pricing) => pricing.type.name)),
      }),
      {},
    );

    const sortedGroupedByCategoryAndType = sortObject(
      groupedByCategoryAndType,
      [
        { key: PricingCategoriesNameEnum.JunkRemoval, index: 0 },
        { key: PricingCategoriesNameEnum.ExpressJunk, index: 1 },
        { key: PricingCategoriesNameEnum.GeneralLabor, index: 2 },
        { key: PricingCategoriesNameEnum.MoveLabor, index: 3 },
        { key: PricingCategoriesNameEnum.Moving, index: 4 },
        { key: PricingCategoriesNameEnum.MovingAccessorial, index: 5 },
      ],
      100,
    );

    return sortedGroupedByCategoryAndType;
  }, [filteredPricings]);

  const handleChangeCategory = useCallback((value: number) => {
    setFilters((prevValue) => ({
      ...prevValue,
      category: value,
    }));

    lastCategory.current = value;
  }, []);

  const handleChangeType = useCallback((value: number) => {
    setFilters((prevValue) => ({
      ...prevValue,
      type: prevValue.type === value ? null : value,
    }));
  }, []);

  const handleChangeSearch = useCallback((value: string) => {
    setFilters((prevValue) => ({
      ...prevValue,
      search: value,
      category: !!value ? PricingCategoriesEnum.All : lastCategory.current,
    }));
  }, []);

  const handleOnChangeFavorite = useCallback((pricingId: number) => {
    setFavoritePricingEv({ pricingId });
  }, []);

  const handleOnPricingClick = useCallback(
    (pricing: PricingItem) =>
      navigate(
        routePaths.jobDetailsAddProductNew(Number(appointmentId), pricing.key),
        {
          replace: true,
          state: location.state,
        },
      ),
    [appointmentId, location, navigate],
  );

  const handleChangeQuantity = useCallback(
    (value: number | null, pricing: PricingItem) => {
      if (value === null) {
        return false;
      }

      const productFromCart = products.find(
        (product) => product.productLineId === pricing.productLineId,
      );

      if (
        productFromCart &&
        productFromCart.quantity === value &&
        productFromCart.actualPrice === pricing.actualPrice &&
        productFromCart.salesTaxId === pricing.tax.id &&
        productFromCart.notes === pricing.notes
      ) {
        deletePricingUpdate({
          key: pricing.key,
        });

        updatePricingItem({ ...pricing, quantity: value });
      } else {
        setPricingUpdate({
          pricing: { ...pricing, quantity: value },
          ignoreDelete: !!productFromCart,
        });

        if (value === 0) {
          setPricingForUpdate({ ...pricing, quantity: value });
        }
      }

      return true;
    },
    [products],
  );

  const handleOnBack = useCallback(() => {
    if (location.state?.addProductBack) {
      navigate(location.state.addProductBack, { state: location.state });
    } else {
      navigate(routePaths.jobDetails(Number(appointmentId)), {
        state: location.state,
      });
    }
  }, [appointmentId, location, navigate]);

  const handleTryAgain = useCallback(async () => {
    if (Number(appointmentId) === appointment?.id && locationId) {
      await getPricingsFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        postal: appointment.origin.postal,
        categoryId: appointment.category.id,
        jobDate: moment(appointment.startDate).format(apiDateFormat),
      });
      triggerRecalculatePricingItems();
    }
  }, [
    appointmentId,
    appointment?.location.id,
    appointment?.id,
    appointment?.origin.postal,
    appointment?.category.id,
    appointment?.startDate,
    locationId,
  ]);

  const handleContinueToCart = useCallback(
    () =>
      navigate(routePaths.jobDetailsCart(Number(appointmentId)), {
        state: location.state,
      }),
    [appointmentId, location.state, navigate],
  );

  const handleSaveAndContinue = useCallback(async () => {
    if (!appointment?.id) {
      return;
    }

    if (updates.length === 0) {
      handleContinueToCart();
      return;
    }

    setIsSaving(true);

    let newProducts: PricingItem[] = [];
    const updatedProducts: PricingItem[] = [];

    updates.forEach((update) => {
      const existingProduct = products.find(
        (product) => product.productLineId === update.productLineId,
      );

      if (!existingProduct) {
        newProducts.push(update);
      } else {
        updatedProducts.push(update);
      }
    });

    newProducts = newProducts.sort(
      (a, b) => Number(b.isOriginal) - Number(a.isOriginal),
    );

    const productsResult = [
      ...products.map((product) => {
        const productInUpdates = updatedProducts.find(
          (updatedProduct) =>
            updatedProduct.productLineId === product.productLineId,
        );

        if (productInUpdates) {
          return {
            actualPrice: productInUpdates.actualPrice,
            retailPrice: product.retailPrice,
            qty: productInUpdates.quantity,
            salesTaxId: productInUpdates.tax.id ?? 0,
            appointment: {
              id: appointment.id,
            },
            product: {
              id: product.productId,
            },
            notes: productInUpdates.notes,
          };
        } else {
          return {
            actualPrice: product.actualPrice,
            retailPrice: product.retailPrice,
            qty: product.quantity,
            salesTaxId: product.salesTaxId ?? 0,
            appointment: {
              id: appointment.id,
            },
            product: {
              id: product.productId,
            },
            notes: product.notes,
          };
        }
      }),
      ...newProducts.map((product) => ({
        actualPrice: product.actualPrice,
        retailPrice: product.price,
        qty: product.quantity,
        salesTaxId: product.tax.id ?? 0,
        appointment: {
          id: appointment.id,
        },
        product: {
          id: product.id,
        },
        notes: product.notes,
      })),
    ].filter((product) => product.qty > 0);

    try {
      await setProductsFx({
        appointmentId: appointment.id,
        locationId: appointment.location.id,
        payload: {
          products: productsResult,
        },
      });
    } catch {
      showAlert("Error! Failed to add items to cart. Try again later.", {
        variant: "error",
      });

      setIsSaving(false);

      return;
    }

    if (productsResult.length === 0) {
      await deleteCompletedAction(
        appointment.id,
        appointment.location.id,
        CompletedActionsJobEnum.AddProducts,
      );
    } else {
      await addCompletedAction(
        appointment.id,
        appointment.location.id,
        CompletedActionsJobEnum.AddProducts,
      );
    }

    let isUpdateAppointmentSuccess = true;
    try {
      await updateAppointmentFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
      });

      showAlert("Success! Your items have been aded to cart.", {
        variant: "success",
      });
    } catch {
      showAlert("Success! Your items have been aded to cart.", {
        variant: "success",
      });

      showAlert("Error! Failed to update appointment.", {
        variant: "error",
      });

      isUpdateAppointmentSuccess = false;
    }

    setPricingUpdates([]);
    setIsSaving(false);

    if (isUpdateAppointmentSuccess) {
      handleContinueToCart();
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }, [
    appointment?.id,
    appointment?.location.id,
    products,
    updates,
    addCompletedAction,
    deleteCompletedAction,
    handleContinueToCart,
    showAlert,
  ]);

  const handleBackToAppointment = useCallback(
    () => navigate(routePaths.jobDetails(Number(appointmentId))),
    [appointmentId, navigate],
  );

  useEffect(() => {
    if (!pricingForUpdate) {
      return;
    }

    const pricingsItemsWithSameId = pricingItems.filter(
      (pricingItem) => pricingItem.id === pricingForUpdate.id,
    );

    if (!pricingForUpdate.isOriginal && pricingForUpdate.quantity === 0) {
      deletePricingItem({ key: pricingForUpdate.key });
    } else if (
      pricingForUpdate.isOriginal &&
      pricingForUpdate.quantity === 0 &&
      pricingsItemsWithSameId.length > 1
    ) {
      updatePricingItem({ ...pricingForUpdate, isHidden: true });
    }

    setPricingForUpdate(null);
  }, [pricingForUpdate]);

  useEffect(() => {
    if (appointment?.category.id) {
      switch (appointment.category.id) {
        case CategoryIdEnum.ExpressJunk:
          setFilters((prevValue) => ({
            ...prevValue,
            category: PricingCategoriesEnum.ExpressJunk,
          }));
          lastCategory.current = PricingCategoriesEnum.ExpressJunk;
          break;
        case CategoryIdEnum.JunkRemoval:
          setFilters((prevValue) => ({
            ...prevValue,
            category: PricingCategoriesEnum.JunkRemoval,
          }));
          lastCategory.current = PricingCategoriesEnum.JunkRemoval;
          break;
        case CategoryIdEnum.Labor:
          setFilters((prevValue) => ({
            ...prevValue,
            category: PricingCategoriesEnum.GeneralLabor,
          }));
          lastCategory.current = PricingCategoriesEnum.GeneralLabor;
          break;
        case CategoryIdEnum.MoveLabor:
          setFilters((prevValue) => ({
            ...prevValue,
            category: PricingCategoriesEnum.MoveLabor,
          }));
          lastCategory.current = PricingCategoriesEnum.MoveLabor;
          break;
        case CategoryIdEnum.Moving:
          setFilters((prevValue) => ({
            ...prevValue,
            category: PricingCategoriesEnum.Moving,
          }));
          lastCategory.current = PricingCategoriesEnum.Moving;
          break;
        default:
          break;
      }
    }
  }, [appointment?.category.id]);

  useEffect(() => {
    if (Number(appointmentId) !== appointment?.id && locationId) {
      getAppointmentFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointment?.id, locationId, appointmentId]);

  useEffect(() => {
    if (appointment?.id && pricingsAppointmentId !== appointment.id) {
      getPricingsFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        postal: appointment.origin.postal,
        categoryId: appointment.category.id,
        jobDate: moment(appointment.startDate).format(apiDateFormat),
      });
    }
  }, [
    pricingsAppointmentId,
    appointment?.location.id,
    appointment?.id,
    appointment?.origin.postal,
    appointment?.category.id,
    appointment?.startDate,
  ]);

  useEffect(() => {
    if (Number(appointmentId) !== developmentsAppointmentId && locationId) {
      getDevelopmentsFx({
        locationId: locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [appointmentId, developmentsAppointmentId, locationId]);

  useEffect(() => {
    setActionButton({
      name: "Notes",
      icon: <InfoOutlined color="inherit" fontSize="small" />,
      disabled: appointmentLoading,
      onClick: () => handleNotesDialogOpen(),
    });

    return () => {
      clearActionButton();
    };
  }, [
    appointmentLoading,
    clearActionButton,
    handleNotesDialogOpen,
    setActionButton,
  ]);

  useEffect(() => {
    return () => {
      setPricingUpdates([]);
      triggerRecalculatePricingItems();
    };
  }, []);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <BackTitle
        title="Add product"
        onBack={handleOnBack}
        classes={{
          controlPanelRoot: styles.controlPanelRoot,
          controlPanelActionsContainer: styles.controlPanelActionsContainer,
          controlPanelContentContainer: styles.controlPanelContentContainer,
          title: styles.title,
        }}
        content={
          filters.category && (
            <div style={{ marginRight: isMobile ? 0 : -8 }}>
              <CategorySelect
                size="large"
                value={filters.category}
                onChange={(e) => handleChangeCategory(Number(e.target.value))}
                options={pricingsCategoryOptions}
              />
            </div>
          )
        }
        actionButtons={[
          ...(!isMobile
            ? [
                {
                  name: "Notes",
                  icon: <InfoOutlined color="inherit" fontSize="small" />,
                  disabled: appointmentLoading,
                  onClick: handleNotesDialogOpen,
                },
              ]
            : []),
        ]}
      >
        <div style={{ marginTop: 12 }}>
          <Input
            value={filters.search}
            onChange={(e) => handleChangeSearch(e.target.value)}
            size="medium"
            variant="outlined"
            placeholder="Search for items"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon color={colors.secondary.main} />
              </InputAdornment>
            }
          />
        </div>
      </BackTitle>
      <div
        style={{
          padding: 16,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!!pricingTypeFilterOptions.length && (
          <div>
            <Scrollbars
              autoHide
              autoHeight
              autoHeightMin="100%"
              autoHeightMax="100%"
              style={{ height: "100%" }}
            >
              <div style={{ display: "flex", marginBottom: 8 }}>
                {pricingTypeFilterOptions.map((filter, i) => (
                  <div
                    style={{
                      marginRight:
                        i !== pricingTypeFilterOptions.length - 1 ? 16 : 0,
                    }}
                    key={i}
                  >
                    <FilterPicker
                      title={filter.label}
                      isActive={filters.type === filter.value}
                      onClick={() => handleChangeType(filter.value)}
                    />
                  </div>
                ))}
              </div>
            </Scrollbars>
          </div>
        )}
        <div
          style={{
            flex: 1,
            marginTop: !!pricingTypeFilterOptions.length ? 8 : 0,
            display: noPricings ? "flex" : "initial",
            alignItems: noPricings ? "center" : "initial",
            justifyContent: noPricings ? "center" : "initial",
          }}
        >
          <PricingsList
            groupedPricings={groupedPricings}
            isAllCategories={filters.category === PricingCategoriesEnum.All}
            noPricing={noPricings}
            loading={pricingsLoading}
            disable={isSaving}
            onChangeQuantity={handleChangeQuantity}
            onChangeFavorite={handleOnChangeFavorite}
            onPricingClick={handleOnPricingClick}
            errorCallback={(error) => setIsError(error)}
          />
        </div>
      </div>
      <ActionsFooter
        show={!!appointment && !appointmentLoading}
        actions={[
          {
            label: "Try again?",
            buttonType: "filled",
            hide: !noPricings,
            onClick: handleTryAgain,
          },
          {
            label:
              updates.length === 0
                ? "Continue To Cart"
                : "Save & Continue To Cart",
            buttonType: "filled",
            isLoading: isSaving,
            disabled: updates.length > 0 && isError,
            hide: noPricings,
            onClick: handleSaveAndContinue,
          },
          {
            label: "Back to appointment",
            buttonType: "text",
            hide: noPricings,
            onClick: handleBackToAppointment,
          },
        ]}
      />

      <Outlet />
    </div>
  );
});
