import { Button } from "@chhjpackages/components";
import { memo, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "effector-react/compat";

import { StepLink, Timer } from "shared/ui";
import { StepStatusesEnum } from "shared/types";
import { $settings } from "features/settings";

import { getStepTwoLinks, ProcessWorkProps } from "./model";
import { useStyles } from "./assets";

export const ProcessWork = memo(
  ({
    appointment,
    status,
    time,
    isPaused,
    completedActions,
    requirements,
    billOfLadingDenied,
    onStartWork,
    onPauseWork,
    onEndWork,
    onEditTime,
  }: ProcessWorkProps) => {
    const styles = useStyles();

    const { pathname } = useLocation();

    const { preJobInspection, postJobInspetion } = useStore($settings);

    const [isStartWorkLoading, setIsStartWorkLoading] = useState(false);
    const [isPauseWorkLoading, setIsPauseWorkLoading] = useState(false);
    const [isEndWorkLoading, setIsEndWorkLoading] = useState(false);

    const isNasa = useMemo(
      () => !!appointment?.subpartner.id,
      [appointment?.subpartner.id],
    );

    const workLinks = useMemo(
      () =>
        getStepTwoLinks(
          completedActions,
          requirements,
          isNasa,
          appointment,
          preJobInspection,
          postJobInspetion,
          billOfLadingDenied,
          pathname,
        ),
      [
        appointment,
        requirements,
        billOfLadingDenied,
        completedActions,
        isNasa,
        pathname,
        preJobInspection,
        postJobInspetion,
      ],
    );

    const handleStartWork = useCallback(async () => {
      setIsStartWorkLoading(true);

      await onStartWork();

      setIsStartWorkLoading(false);
    }, [onStartWork]);

    const handlePauseWork = useCallback(async () => {
      setIsPauseWorkLoading(true);

      await onPauseWork();

      setIsPauseWorkLoading(false);
    }, [onPauseWork]);

    const handleEndWork = useCallback(async () => {
      setIsEndWorkLoading(true);

      await onEndWork();

      setIsEndWorkLoading(false);
    }, [onEndWork]);

    return (
      <div className={styles.root}>
        <Timer initialTime={time} onEdit={onEditTime} />

        {workLinks.length > 0 && (
          <div className={styles.workLinksList}>
            {workLinks.map((stepTwoLink) => (
              <StepLink
                key={stepTwoLink.id}
                path={stepTwoLink.path(Number(appointment?.id))}
                pathState={stepTwoLink.pathState}
                name={stepTwoLink.name}
                isChecked={stepTwoLink.isChecked}
              />
            ))}
          </div>
        )}

        {status === StepStatusesEnum.InProgress || isPaused ? (
          <div className={styles.actionButtonsContainer}>
            <Button
              buttonType="text"
              color="primary"
              fullWidth
              isLoading={isPauseWorkLoading}
              className={styles.actionButton}
              onClick={handlePauseWork}
            >
              {isPaused ? "Resume work" : "Pause time"}
            </Button>

            <Button
              buttonType="twoTone"
              fullWidth
              isLoading={isEndWorkLoading}
              className={styles.actionButton}
              onClick={handleEndWork}
            >
              End work
            </Button>
          </div>
        ) : (
          <Button
            buttonType="twoTone"
            size="medium"
            fullWidth
            onClick={handleStartWork}
            isLoading={isStartWorkLoading}
          >
            Start working
          </Button>
        )}
      </div>
    );
  },
);
