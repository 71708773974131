import { Button, colors, useAlert } from "@chhjpackages/components";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useStore } from "effector-react/compat";

import {
  generateSquareDeepLink,
  getTransactionResult,
  PaymentProviderEnum,
  recordSquarePayment,
  SendItemizedReceiptForm,
  SendItemizedReceiptFormSkeleton,
  SendItemizedReceiptFormValues,
  SquareErrorCodesAndroidEnum,
  SquareErrorCodesIOsEnum,
  usePaymentProvider,
} from "features/payments";
import { getOS, routePaths } from "shared/utils";
import { $auth } from "features/auth";
import {
  $appointmentStore,
  getAppointmentFx,
  markAppointmentCompletedFx,
  sendAppointmentInvoiceFx,
} from "features/appointment";
import { useSideNavDispatch } from "features/sidenav";
import { ActionsFooter, BackTitle } from "shared/ui";
import { AppointmentStatus, CompletedActionsJobEnum } from "shared/types";
import { useCompletedActions } from "features/completed-actions";

import { useResultStyles } from "./assets";

export const PaymentResult = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const styles = useResultStyles();

  const { locationId } = useStore($auth);
  const { appointment } = useStore($appointmentStore);

  const location = useLocation();
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const { showAlert } = useAlert();
  const {
    setPageName,
    setShowGoBackButton,
    setGoToBackUrl,
    setGoToBackOptions,
  } = useSideNavDispatch();
  const { addCompletedAction } = useCompletedActions();
  const paymentProvider = usePaymentProvider();
  const [searchParams] = useSearchParams();

  const successSearch = useMemo(() => {
    const succesSearchValue = searchParams.get("success");

    if (succesSearchValue && !isNaN(Number(succesSearchValue))) {
      return Number(succesSearchValue);
    }
    return null;
  }, [searchParams]);

  const thereIsSuccessSearch = useMemo(
    () => typeof successSearch === "number",
    [successSearch],
  );

  const [isSuccess, setIsSuccess] = useState<boolean | null>(
    thereIsSuccessSearch ? successSearch === 1 : null,
  );
  const [errorCode, setErrorCode] = useState("");
  const [clientTransactionId, setClientTransactionId] = useState<string>();
  const [serverTransactionId, setServerTransactionId] = useState<string>();
  const [isRecordingPayment, setIsRecordingPayment] = useState(
    thereIsSuccessSearch ? false : true,
  );
  const [isRecordPaymentError, setIsRecordPaymentError] = useState(false);
  const [isMarkingJobAsCompleted, setIsMarkingJobAsCompleted] = useState(false);

  const system = useMemo(() => getOS(), []);

  const isSquare = useMemo(
    () => paymentProvider === PaymentProviderEnum.Square,
    [paymentProvider],
  );

  const squareNotLoggedIn = useMemo(
    () =>
      errorCode === SquareErrorCodesAndroidEnum.NOT_LOGGED_IN ||
      errorCode === SquareErrorCodesAndroidEnum.NO_EMPLOYEE_LOGGED_IN ||
      errorCode === SquareErrorCodesIOsEnum.NOT_LOGGED_IN,
    [errorCode],
  );

  const errorCodeForDisplay = useMemo(
    () => errorCode.replace("com.squareup.pos.", ""),
    [errorCode],
  );

  const showMarkJobAsCompleted = useMemo(
    () =>
      !!isSuccess &&
      !!appointment &&
      !isRecordingPayment &&
      !isRecordPaymentError,
    [isSuccess, appointment, isRecordingPayment, isRecordPaymentError],
  );

  const isDisableMarkJobAsCompleted = useMemo(
    () =>
      appointment?.type === "EST"
        ? appointment.estStatus.id === AppointmentStatus.Estimate.Completed
        : appointment?.status.id === AppointmentStatus.Job.Completed,
    [appointment?.type, appointment?.estStatus.id, appointment?.status.id],
  );

  const goToBackUrl = useMemo(
    () =>
      isSuccess
        ? location.state?.paymentBack ??
          routePaths.jobDetails(Number(appointmentId))
        : isSquare
        ? routePaths.jobDetailsPayments()
        : routePaths.jobDetailsPaymentsCollect(Number(appointmentId)),
    [appointmentId, isSuccess, isSquare, location.state?.paymentBack],
  );

  const pageName = useMemo(() => {
    let result = "Payment";

    if (isSuccess === true) {
      if (isRecordPaymentError) {
        result += " error";
      } else {
        result += " accepted";
      }
    } else if (isSuccess === false) {
      if (squareNotLoggedIn) {
        result += " error";
      } else {
        result += " declined";
      }
    }

    return result;
  }, [isSuccess, isRecordPaymentError, squareNotLoggedIn]);

  const handleGoBack = useCallback(() => {
    navigate(goToBackUrl, {
      state: location.state,
    });
  }, [goToBackUrl, location.state, navigate]);

  const recordPayment = useCallback(
    async (
      appId?: number,
      accId?: number,
      locId?: number,
      serverTransId?: string,
      clientTransId?: string,
    ) => {
      if (!appointment?.id) {
        return;
      }

      setIsRecordingPayment(true);

      const apptIdVal = appId ?? appointment.id;
      const accIdVal = accId ?? appointment.account.id;
      const locIdVal = locId ?? appointment.location.id;

      try {
        const recordPaymentResponse = await recordSquarePayment({
          payload: {
            state: `${apptIdVal}-${accIdVal}-${locIdVal}`,
            type: serverTransId ? "server_id" : "client_id",
            transactionId: (serverTransId ?? clientTransId) as string,
            forceProduction: true,
          },
        });

        if (
          recordPaymentResponse.status !== 200 ||
          (recordPaymentResponse.data.meta.errors?.length ?? 0) > 0
        ) {
          throw new Error();
        }

        if (location.search) {
          navigate(
            { pathname: location.pathname, search: "" },
            { replace: true, state: location.state },
          );
        }

        await getAppointmentFx({
          locationId: locIdVal,
          appointmentId: apptIdVal,
        });

        setIsRecordPaymentError(false);
        showAlert("Success! Payment has been added.", {
          variant: "success",
        });
      } catch {
        setIsRecordPaymentError(true);
        showAlert("Error! Failed to add payment.", {
          variant: "error",
        });
      }

      setIsRecordingPayment(false);
    },
    [
      location,
      appointment?.id,
      appointment?.location.id,
      appointment?.account.id,
      navigate,
      showAlert,
    ],
  );

  const handleSubmitSendItemizedReceipt = useCallback(
    async (data: SendItemizedReceiptFormValues) => {
      if (!appointment) {
        return;
      }

      try {
        await sendAppointmentInvoiceFx({
          locationId: appointment.location.id,
          appointmentId: appointment.id,
          payload: {
            emailTo: data.email,
          },
        });

        showAlert("Success! Itemized receipt has been sent.", {
          variant: "success",
        });
      } catch {
        showAlert("Error! Failed to send itemized receipt.", {
          variant: "error",
        });
      }
    },
    [appointment, showAlert],
  );

  const handleTryAgain = useCallback(() => {
    if (!appointment) {
      return;
    }

    if (isSquare) {
      const deepLink = generateSquareDeepLink(
        appointment,
        "USD",
        system,
        window.location.origin,
        location.state?.paymentBack,
      );

      if (deepLink) {
        window.location.href = deepLink;
      } else {
        showAlert("Available only on mobile devices.", {
          variant: "warning",
        });
      }
    } else {
      navigate(routePaths.jobDetailsPaymentsCollect(Number(appointmentId)), {
        state: location.state,
      });
    }
  }, [
    system,
    appointment,
    appointmentId,
    isSquare,
    location.state,
    navigate,
    showAlert,
  ]);

  const handleMarkJobAsCompleted = useCallback(async () => {
    if (!appointment?.id) {
      return;
    }

    setIsMarkingJobAsCompleted(true);

    try {
      await markAppointmentCompletedFx({
        locationId: appointment.location.id,
        appointmentId: appointment.id,
        type: appointment.type,
      });

      await addCompletedAction(
        appointment.id,
        appointment.location.id,
        CompletedActionsJobEnum.CompleteJob,
      );

      showAlert("Success! Job has been marked as completed.", {
        variant: "success",
      });

      navigate(routePaths.jobDetails(appointment.id));
    } catch {
      showAlert("Error! Failed to mark job as completed.", {
        variant: "success",
      });
    }

    setIsMarkingJobAsCompleted(false);
  }, [
    appointment?.location.id,
    appointment?.id,
    appointment?.type,
    addCompletedAction,
    navigate,
    showAlert,
  ]);

  useEffect(() => {
    if (!location.search || thereIsSuccessSearch) {
      return;
    }

    const result = getTransactionResult(window.location.href, system);

    if (location.pathname === routePaths.squarePaymentResult() && result.meta) {
      navigate(
        `${routePaths.jobDetailsPaymentsResult(
          Number(result.meta.appointmentId),
        )}${location.search}`,
        {
          replace: true,
          state: {
            paymentBack: result.meta.paymentBack,
          },
        },
      );
      return;
    }

    if (
      result?.success &&
      (result.clientTransactionId || result.serverTransactionId)
    ) {
      setIsSuccess(true);

      if (result.clientTransactionId) {
        setClientTransactionId(result.clientTransactionId);
      }

      if (result.serverTransactionId) {
        setServerTransactionId(result.serverTransactionId);
      }
    } else if (!result?.success) {
      setErrorCode(result.errorCode);
      setIsSuccess(false);
    }
  }, [system, location, thereIsSuccessSearch, navigate]);

  useEffect(() => {
    setPageName(pageName);
    setGoToBackUrl(goToBackUrl);
    setShowGoBackButton(true);
    setGoToBackOptions({ state: location.state });

    return () => {
      setPageName("");
      setGoToBackUrl("");
      setShowGoBackButton(false);
      setGoToBackOptions(undefined);
    };
  }, [
    pageName,
    goToBackUrl,
    location.state,
    setPageName,
    setGoToBackUrl,
    setShowGoBackButton,
    setGoToBackOptions,
  ]);

  useEffect(() => {
    if (appointment && (clientTransactionId || serverTransactionId)) {
      recordPayment(
        appointment.id,
        appointment.account.id,
        appointment.location.id,
        serverTransactionId,
        clientTransactionId,
      );
    }
  }, [
    appointment?.id,
    appointment?.account.id,
    appointment?.location.id,
    clientTransactionId,
    serverTransactionId,
  ]);

  useEffect(() => {
    if (locationId && Number(appointmentId)) {
      getAppointmentFx({
        locationId,
        appointmentId: Number(appointmentId),
      });
    }
  }, [locationId, appointmentId]);

  return (
    <>
      {!isMobile && <BackTitle onBack={handleGoBack} title={pageName} />}

      {typeof isSuccess === "boolean" && (
        <div className={styles.root}>
          <div className={styles.contentRoot}>
            <div className={styles.content}>
              <div className={styles.resultTextContainer}>
                <Typography variant="h4" color="secondary">
                  {isSuccess
                    ? isRecordPaymentError
                      ? "The payment processed, however it was not recorded correctly."
                      : "Payment has been accepted"
                    : squareNotLoggedIn
                    ? "Not logged into square"
                    : "Payment has been declined"}
                </Typography>

                <Typography variant="body2" style={{ color: colors.grey180 }}>
                  {isSuccess
                    ? isRecordPaymentError
                      ? "Please make sure you have your credentials set up correctly and that you are connected to the internet to continue."
                      : "Would you like to send a copy of the itemized receipt to the client?"
                    : squareNotLoggedIn
                    ? "Please ensure that the Square app is open and you have logged in using your device code before attempting payment."
                    : "Don’t worry, you can try again with another method of payment."}
                </Typography>
              </div>

              {isSuccess ? (
                <div className={styles.successSection}>
                  {isRecordPaymentError ? (
                    <Button
                      buttonType="twoTone"
                      fullWidth
                      isLoading={isRecordingPayment}
                      onClick={() => {
                        if (appointment) {
                          recordPayment(
                            undefined,
                            undefined,
                            undefined,
                            serverTransactionId,
                            clientTransactionId,
                          );
                        }
                      }}
                    >
                      Retry recording payment
                    </Button>
                  ) : appointment && !isRecordingPayment ? (
                    <SendItemizedReceiptForm
                      initialValues={{ email: appointment.account.email }}
                      onSubmit={handleSubmitSendItemizedReceipt}
                    />
                  ) : (
                    <SendItemizedReceiptFormSkeleton />
                  )}
                </div>
              ) : (
                <div className={styles.errorSection}>
                  <Button
                    buttonType="twoTone"
                    fullWidth
                    onClick={handleTryAgain}
                  >
                    Try again?
                  </Button>
                </div>
              )}
            </div>

            {errorCodeForDisplay && (
              <Typography
                variant="body1"
                align="center"
                className={styles.errorCode}
              >
                Error code: {errorCodeForDisplay}
              </Typography>
            )}
          </div>

          <ActionsFooter
            show={showMarkJobAsCompleted}
            actions={[
              {
                label: "Mark job as complete",
                buttonType: "filled",
                disabled: isDisableMarkJobAsCompleted,
                isLoading: isMarkingJobAsCompleted,
                onClick: handleMarkJobAsCompleted,
              },
            ]}
          />
        </div>
      )}
    </>
  );
});
