import { memo, ReactNode, useCallback, useMemo, useState } from "react";

import { PageLayout } from "shared/templates";
import { SideNavController } from "../sidenav";

import {
  SidebarDispatchContext,
  SidebarDispatchContextValue,
  SidebarStateContext,
  SidebarStateContextValue,
} from "./model";

export const Sidebar = memo(
  ({ children, logout }: { children: ReactNode; logout: () => void }) => {
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const [sidebarContent, setSidebarContent] = useState<ReactNode | null>(
      null,
    );

    const showSidebar = useCallback(() => {
      setIsShowSidebar(true);
    }, []);

    const hideSidebar = useCallback(() => {
      setIsShowSidebar(false);
    }, []);

    const handleSetSidebarContent = useCallback(
      (content: ReactNode) => {
        setSidebarContent(content);
      },
      [setSidebarContent],
    );

    const clearSidebarContent = useCallback(() => {
      handleSetSidebarContent(null);
    }, [handleSetSidebarContent]);

    const renderSidebar = useCallback(
      () => (isShowSidebar && sidebarContent ? sidebarContent : null),
      [isShowSidebar, sidebarContent],
    );

    const state: SidebarStateContextValue = useMemo(
      () => ({
        isShowSidebar,
        sidebarContent,
      }),
      [isShowSidebar, sidebarContent],
    );

    const dispatchValues: SidebarDispatchContextValue = useMemo(() => {
      return {
        clearSidebarContent,
        hideSidebar,
        showSidebar,
        setSidebarContent: handleSetSidebarContent,
      };
    }, [
      showSidebar,
      hideSidebar,
      handleSetSidebarContent,
      clearSidebarContent,
    ]);

    return (
      <SidebarStateContext.Provider value={state}>
        <SidebarDispatchContext.Provider value={dispatchValues}>
          <SideNavController>
            <PageLayout sidebar={renderSidebar()} logout={logout}>
              {children}
            </PageLayout>
          </SideNavController>
        </SidebarDispatchContext.Provider>
      </SidebarStateContext.Provider>
    );
  },
);
